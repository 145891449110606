import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer-leaves"
export default class extends Controller {
  static targets = ['startDate', 'endDate', 'submitBtn', 'customerId']

  updateDate(event) {

    const startDate = new Date(this.startDateTarget.value);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(this.endDateTarget.value);
    endDate.setHours(0, 0, 0, 0);

    let eventType = event.target.dataset.customerLeavesTarget;
    this.validateDateRange(startDate, endDate, eventType);
  }

  validateDateRange(startDate, endDate, eventType) {
    if (startDate > endDate) {
      this.endDateTarget.classList.add("border-red-500");
      this.disableButton();
    } else {
      this.enableButton();
      this.endDateTarget.classList.remove("border-red-500");
      this.setMinDateOnEndDateField(eventType, startDate)
    }
  }
  enableButton() {
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableButton() {
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  setMinDateOnEndDateField(eventType, startDate) {
    if (eventType === "startDate") {
      startDate.setDate(startDate.getDate() + 1);
      this.endDateTarget.setAttribute('min', startDate.toISOString().split('T')[0]);
    }
  }

  validateLeave(event) {
    event.preventDefault();
    const form = event.currentTarget.form;
    const token = document.getElementsByName('csrf-token')[0].content;
    const data = {
      startDate: this.startDateTarget.value,
      endDate: this.endDateTarget.value,
      customerId: this.customerIdTarget.value
    };

    if (Object.values(data).some(value => value === '')) {
      alert('Please fill in all fields.');
      return;
    }
    const url = `/web/customers/${parseInt(data.customerId)}/jar_transactions_date`;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({
        leave_info: data
      })
    })
    .then(response => response.json())
    .then(response => {
      if (response.transactions_date.length > 0) {
        let confirmation = confirm(`Are you sure?\n Jar-transaction is already exist for ${response.transactions_date}.\n If you want, you can still add leaves.`);
        if (!confirmation) {
          return;
        }
      }
      Turbo.navigator.submitForm(form);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
}
