import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {
  static targets = ["password", "confirmPassword", "visibleIcon", "hideIcon", "visibleIconCP", "hideIconCP", "submitButton", "errorMessage"];

  connect() {
    // Listen for input events on the password field
    this.passwordTarget.addEventListener('input', () => {
      this.validatePassword();
    });
  }

  visiblePassword() {
    this.visibleIconTarget.hidden = !this.visibleIconTarget.hidden;
    this.hideIconTarget.hidden = !this.hideIconTarget.hidden;
    this.passwordTarget.type = this.passwordTarget.type === "password" ? "text" : "password";
  }

  visibleConfirmPassword() {
    this.visibleIconCPTarget.hidden = !this.visibleIconCPTarget.hidden;
    this.hideIconCPTarget.hidden = !this.hideIconCPTarget.hidden;
    this.confirmPasswordTarget.type = this.confirmPasswordTarget.type === "password" ? "text" : "password";
  }

  validatePassword() {
    const password = this.passwordTarget.value;
    const confirmPassword = this.confirmPasswordTarget.value;

    if (confirmPassword && password !== confirmPassword) {
      this.hideSubmitButton();
      this.errorMessageTarget.textContent = "Passwords do not match";
    } else if (password && password.length < 6) {
      this.hideSubmitButton();
      this.errorMessageTarget.textContent = "Password must be at least 6 characters long";
    } else {
      this.errorMessageTarget.textContent = "";
      this.showSubmitButton();
    }
  }

  hideSubmitButton(){
    this.submitButtonTarget.disabled = true;
  }

  showSubmitButton(){
    this.submitButtonTarget.disabled = false;
  }
}
