import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fancy-select"
export default class extends Controller {
  static targets = ["list", "item", "selected", "input", "searchField"];

  connect() {
    this.selectedOption();
    document.addEventListener('click', this.closeDropdown.bind(this));
  }

  toggle() {
    if (this.listTarget.classList.contains("hidden")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.listTarget.classList.remove("hidden");
    this.selectedTarget.classList.add("border-2", "border-gray-500");
  }

  close() {
    this.listTarget.classList.add("hidden");
    this.selectedTarget.classList.remove("border-2");
    this.selectedTarget.classList.remove("border-gray-500");
  }

  choose(e) {
    const selectItem = e.currentTarget;
    const id = selectItem.dataset.id;

    // Copy the label HTML and place it into the outer form element]
    selectItem
      .querySelector(`[data-js="title"]`)
      .classList.add("font-semibold");
    this.selectedTarget.innerHTML =
      selectItem.querySelector(`[data-js="label"]`).outerHTML;
    // Set the hidden inputs value to the ID
    this.inputTarget.value = id;
    // Remove checkmarks from all other elements
    this.itemTargets.forEach((itemTarget) => {
      itemTarget
        .querySelector(`[data-js="title"]`)
        .classList.remove("font-semibold");
      itemTarget.querySelector(`[data-js="check"]`).classList.add("hidden");
    });
    // Add checkmark to selected item.
    selectItem
      .querySelector(`[data-js="title"]`)
      .classList.add("font-semibold");
    selectItem.querySelector(`[data-js="check"]`).classList.remove("hidden");

    this.close();
  }

  search(event) {
    const searchInput = event.target.value.toLowerCase(); // assigns the value of the text in the input field

    this.itemTargets.forEach((item) => {
      const itemName = item.querySelector(".item-name").textContent.toLowerCase();

      if (itemName.includes(searchInput)) { // if the name of the menu item is the same as whatever is inputted in the search field
        item.classList.remove("hidden"); // display the menu card of the menu item
      } else {
        item.classList.add("hidden"); // hide the menu card of all the items that do not match the input
      }
    });
  }

  clearText() {
    this.searchFieldTarget.value = '';
    this.itemTargets.forEach((item) => { item.classList.remove("hidden"); });
  }
  clearSelectBox() {
    this.selectedTarget.innerHTML = this.selectedTarget.dataset.label;
    this.itemTargets.forEach((item) => {
      item.querySelector(`[data-js="check"]`).classList.add("hidden");
      item.querySelector(`[data-js="title"]`).classList.remove("font-semibold");
    });
    this.selectedTarget.classList.add("text-sm");
    this.inputTarget.value = ''
    this.close();
  }

  // this will show pre-selected option if user select option and then error occur
  selectedOption() {
    const hiddenFieldValue = this.inputTarget.value.trim();
    if (hiddenFieldValue) {
      const selectedItem = this.itemTargets.find((item) => item.dataset.id === hiddenFieldValue);
      if (selectedItem) {
        this.selectedTarget.innerHTML = selectedItem.querySelector('[data-js="label"]').outerHTML;
        selectedItem.querySelector('[data-js="check"]').classList.remove("hidden");
      }
    }
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

}
