import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="date-range"
export default class extends Controller {
  static targets = ['startDate', 'endDate', 'searchBtn', 'searchIconBtn'];

  updateDate(event) {
    const inputDate = new Date(event.target.value);
    inputDate.setHours(0, 0, 0, 0);

    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);

    const startDate = new Date(this.startDateTarget.value);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(this.endDateTarget.value);
    endDate.setHours(0, 0, 0, 0);

    const eventType = event.target.dataset.dateRangeTarget;
    this.validateFutureDate(eventType, inputDate, todaysDate, startDate, endDate);
  }

  validateFutureDate(eventType, inputDate, todaysDate, startDate, endDate) {
    const targetElement =
      eventType === 'startDate' ? this.startDateTarget : this.endDateTarget;
    const isFutureDate = inputDate > todaysDate;

    if (isFutureDate) {
      this.disableButton();
      targetElement.classList.add('border-red-500');
    } else {
      this.setMinDateOnEndDateField(inputDate)
      this.validateDateRange(todaysDate, startDate, endDate);
    }
  }

  validateDateRange(todaysDate, startDate, endDate) {
    if (startDate > endDate || endDate > todaysDate) {
      this.endDateTarget.classList.add('border-red-500');
      this.disableButton();
    } else {
      this.enableButton();
      this.startDateTarget.classList.remove('border-red-500');
      this.endDateTarget.classList.remove('border-red-500');
    }
  }

  updateReportEndDate () {
    let startDate = new Date(this.start_dateTarget.value)
    let endDate = new Date(this.end_dateTarget.value)

    if (!isNaN(startDate) && !isNaN(endDate)) {
      let diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
      if (diffInDays > 365) {
        let newEndDate = new Date(startDate)
        newEndDate.setDate(startDate.getDate() + 365)

        this.end_dateTarget.value = newEndDate.toISOString().split('T')[0]
        this.end_dateTarget.min = startDate.toISOString().split('T')[0]
        this.start_dateTarget.max=newEndDate.toISOString().split('T')[0]
      } else {
        this.end_dateTarget.min = startDate.toISOString().split('T')[0]
      }
    }
  }
  enableButton() {
    this.searchBtnTarget.removeAttribute('disabled');
    this.searchIconBtnTarget.removeAttribute('disabled');
  }

  disableButton() {
    this.searchBtnTarget.setAttribute('disabled', 'disabled');
    this.searchIconBtnTarget.setAttribute('disabled', 'disabled');
  }
  setMinDateOnEndDateField(inputDate) {
    inputDate.setDate(inputDate.getDate() + 1)
    this.endDateTarget.setAttribute(
      'min',
      inputDate.toISOString().split('T')[0]
    )
  }
}
