import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["mobile"]

  toggleMobileBlur(event) {
    this.mobileTargets.forEach(element => {
      element.classList.add('blur-sm', 'cursor-pointer');
    });

    event.currentTarget.classList.remove('blur-sm', 'cursor-pointer');
  }
}