import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['number']

  connect () {
    this.submitBtn = document.getElementById('submitBtn')
  }

  numberIncrement (event) {
    const currentTarget = event.currentTarget.dataset.id;
    const targetNumber = document.getElementById(currentTarget);
    const isFloatOrBigDecimal = ['Float', 'BigDecimal'].includes(targetNumber.dataset.numbertype);

    if (isFloatOrBigDecimal) {
      const currentValue = parseFloat(targetNumber.value) || 0;
      targetNumber.value = (currentValue + 1).toFixed(1);
    } else {
      targetNumber.stepUp(1);
    }
    targetNumber.classList.remove('border-red-500')
    this.validateNumber() 
  }

  numberDecrement (event) {
    const currentTarget = event.currentTarget.dataset.id;
    const targetNumber = document.getElementById(currentTarget);

    if (targetNumber.value > 1) {
      const isFloatOrBigDecimal = ['Float', 'BigDecimal'].includes(targetNumber.dataset.numbertype);

      if (isFloatOrBigDecimal) {
        targetNumber.value = (parseFloat(targetNumber.value) - 1).toFixed(1);
      } else {
        targetNumber.stepDown(1);
      }
      this.validateNumber() 
    }
  }

  validateNumber (event) {
    let isValid = true
    this.numberTargets.forEach(el => {
      if (el.value < (el.min || 1)) {
        isValid = false
        el.classList.add('border-red-500')
        if(event){
          event.preventDefault()
        }
      } else {
        el.classList.remove('border-red-500')
      }
    })

    this.submitBtn.disabled = !isValid
  }

  validateMinValue(event) {
    let allZeros = true;

  this.numberTargets.forEach(el => {
    if (el.value > 0) {
      allZeros = false;
      el.classList.remove('border-red-500');
    } else {
      el.classList.add('border-red-500');
    }
  });

  this.submitBtn.disabled = allZeros;

  if (event && allZeros) {
    event.preventDefault();
  }
  }
}
