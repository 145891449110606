import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="print-reports"
export default class extends Controller {
  static targets = ['report', 'fileName'];
  connect() { }
  
  download() {
    const printContents = this.reportTarget.innerHTML;
    const originalTitle = document.title;
    const fileName = this.reportTarget.dataset.filename;

    const printContainer = document.createElement('div');
    printContainer.innerHTML = printContents;

    printContainer.classList.add('print-area');

    // Hide everything except the selected content during printing
    const style = document.createElement('style');
    style.innerHTML = `
    @media print { 
      @page {
        margin-top: 0;
        margin-bottom: 0;
    }
        body > *:not(.print-area) { display: none !important; }
    }
`;
    document.head.appendChild(style);

    document.body.appendChild(printContainer);

    document.title = fileName;

    window.print();

    document.title = originalTitle;
    document.body.removeChild(printContainer);
    document.head.removeChild(style);
  }
}
