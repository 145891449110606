import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-delivery-update"
export default class extends Controller {
  static targets = [ "delivery_modal", "product_price", "form_data", "delivery_modal_count", "delivery_modal_progress_bar"]

  connect() {

  }

  buildTransactionParams(formData) {
    const data = {};
    formData.forEach((value, key) => {
      if (key.includes('][')) {
          const [fieldName, index, fieldProperty] = key.split(/\[|\]/).filter(Boolean);
          if (!data[fieldName]) {
              data[fieldName] = [];
          }
          data[fieldName][index] = data[fieldName][index] || {};
          data[fieldName][index][fieldProperty] = value;

      } else {
        data[key] = value;
      }
    });

    return data;
  }

  transactionsToSubmit(data) {
    data["transactions"] = data["transactions"].filter(element => element.quantity !== '0' || element.id !== '' || [0,1].includes(element.jar_transaction_type));
    return data;
  }

  shouldSubmitTransaction(transactionData) {
    return (transactionData["transactions"].length > 0 && ((transactionData["delivery_status"] == 'true' && transactionData["is_quantity_changed"] == 'true') || transactionData["delivery_status"] == ''))
  }

  transactionsIndexesToSkip() {
    let totalRequestCount = this.formDataTargets.length
    const skipIndexes = [];

    for (let index = 0; index < totalRequestCount; index++) {
      const form = this.formDataTargets[index]
      const formData = new FormData(form);
      const data = this.buildTransactionParams(formData);
      const transactionData = this.transactionsToSubmit(data)
      if (!this.shouldSubmitTransaction(transactionData)) {
        skipIndexes.push(index)
      }
    }

    return skipIndexes;
  }

  requestJarTransactionUpdate(index, requestCount, skipIndexes) {

    let total_request_count = this.formDataTargets.length;

    if (skipIndexes.includes(index)) {
      if(total_request_count > index+1) {
        this.requestJarTransactionUpdate(index+1, requestCount, skipIndexes)
      } else {
        this.delivery_modalTarget.close();
      }
    }

    const form = this.formDataTargets[index]
    let token = document.getElementsByName('csrf-token')[0].content
    const formData = new FormData(form);
    const data = this.transactionsToSubmit(this.buildTransactionParams(formData))
    // API call
    if (this.shouldSubmitTransaction(data) && this.shouldExecuteTxn) {
      this.model_data_update(total_request_count - skipIndexes.length ,requestCount)
      fetch(form.action, {
        method: "post",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/json",
          "X-CSRF-Token": token
        },
        body: JSON.stringify({
          customer_txns_data: data
        })
      }).then((response) => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        if(total_request_count > index+1) {
          this.requestJarTransactionUpdate(index+1, requestCount+1, skipIndexes)
        } else {
          this.delivery_modalTarget.close();
        }
      })
    }
  }

  update() {
    this.shouldExecuteTxn = true;
    this.formDataTargets = this.form_dataTargets;
    const skipIndexes = this.transactionsIndexesToSkip();
    this.requestJarTransactionUpdate(0, 1, skipIndexes)
  }

  model_data_update(total_request_count,current_count) {
    this.delivery_modal_countTarget.innerHTML = `${current_count}/${total_request_count}`
    this.delivery_modal_progress_barTarget.max = total_request_count
    this.delivery_modal_progress_barTarget.value = current_count
    this.delivery_modalTarget.show();
  }

  skip() {
    const confirmation = confirm(`Are you sure?\n All the remaining deliveries will be skipped.\n if you click on Ok button.`);
    if (confirmation) {
      this.shouldExecuteTxn = false;
    }
  }
}
